import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { ContentfulBlogPost } from "../common/types/contentful-models";
import Title from "@components/UI-Elements/Typography/Title";
import SEO from "@components/seo";

export const query = graphql`
  query ($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      description {
        description
      }
      body {
        body
      }
    }
  }
`;

const Page: React.FC = (data: any) => {
  const contentfulBlogPost: ContentfulBlogPost = data.data.contentfulBlogPost;
  return (
    <Layout>
      <SEO title={contentfulBlogPost.title} robots="noindex" />
      <Title>{contentfulBlogPost.title}</Title>
      <h3>{contentfulBlogPost?.description?.description}</h3>
      <div>{contentfulBlogPost?.body?.body}</div>
    </Layout>
  );
}
export default Page;
